import axios from 'axios'
import { getConfig, getEnvironmentURL } from 'utils/getEnvironmentURL'
import { getQuerystring } from 'utils/querystring'

const ManagementApiService = {}
const baseApiUrlDev = 'https://app-vidsigner-vidcloudmgm-dev.azurewebsites.net/api'
const baseApiUrlPre = 'https://pre-vidsignercloudmgmt.validatedid.com/api'
const baseApiUrlPro = 'https://vidsignercloudmgmt.validatedid.com/api'

ManagementApiService.baseURL = getEnvironmentURL(baseApiUrlPro, baseApiUrlPre, baseApiUrlPre);

ManagementApiService.getCustomerToken = (customerGUID) => {
  const config = getConfig();

  const data = {
    "username": customerGUID,
    "password": "",
    "grant_type": "password",
    "scope": "ASCustomer"
  }


  return new Promise((resolve, reject) => {
    axios.post(`${ManagementApiService.baseURL}/oauth/token`, data, config).then(res => {
      localStorage.setItem('cloud_token', res.data.access_token)
      resolve(true)
    })
  })
}

ManagementApiService.getPartnerToken = (partnerGuid) => {
  const config = getConfig();

  const data = {
    "username": partnerGuid,
    "password": "1234",
    "grant_type": "password",
    "scope": "ASPartner"
  }
  return new Promise((resolve, reject) => {
    axios.post(`${ManagementApiService.baseURL}/oauth/token`, data, config).then(res => {
      localStorage.setItem('cloud_token', res.data.access_token)
      resolve(true);
    })
  })
}

ManagementApiService.getOwnerToken = (ownerGUID) => {
  const config = getConfig();

  const data = {
    "username": ownerGUID,
    "password": "1234",
    "grant_type": "password",
    "scope": "ASOwner"
  }


  return new Promise((resolve, reject) => {
    axios.post(`${ManagementApiService.baseURL}/oauth/token`, data, config).then(res => {
      localStorage.setItem('cloud_token', res.data.access_token)
      resolve(true)
    })
  })
}

ManagementApiService.getCustomers = (page= 1, limit=15, filters) => {
  const querystringFilters = getQuerystring(filters);

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers?PageNumber=${page}&PageSize=${limit}&OrderBy=CustomerName&${querystringFilters}`, config)
}

ManagementApiService.getCustomersWithStats = (page= 1, limit=15, filters, from, to) => {
  const querystringFilters = getQuerystring(filters);

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  
  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers?PageNumber=${page}&PageSize=${limit}&OrderBy=CustomerName&${querystringFilters}&IncludeStats=True&DateFrom=${from}&DateTo=${to}`, config)
}

ManagementApiService.getAllCustomers = () => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers`, config)
}

ManagementApiService.getCustomerDetail = (customerDetail) => {
  const config = {
    headers: { Authorization:  `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers/${customerDetail}`, config)
}

ManagementApiService.getCustomerDetailWithStats = (customerGuid, from, to) => {
  const config = {
    headers: { Authorization:  `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers/${customerGuid}?IncludeStats=True&DateFrom=${from}&DateTo=${to}`, config)
}


ManagementApiService.getCustomerSubscriptions = (customerGUID, page, limit, mockedToken = false) => {
  const config = {
    headers: { Authorization:  `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers/${customerGUID}/subscriptions?PageNumber=${page}&PageSize=${limit}`, config)
}

ManagementApiService.getSubscriptions = (page, limit, filters) => {
  const querystringFilters = getQuerystring(filters);
  const config = {
    headers: { Authorization:  `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscriptions?PageNumber=${page}&PageSize=${limit}&${querystringFilters}`, config)
}

  ManagementApiService.getAllSubscriptions = () => {
  const config = {
    headers: { Authorization:  `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscriptions`, config)
}

ManagementApiService.getSubscriptionsOfACustomer = (CustomerGuid) => {
  const config = {
    headers: { Authorization:  `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscriptions?CustomerGuid=${CustomerGuid}`, config)
}

ManagementApiService.getPartnerSubscriptions = (partnerGUID, page, limit) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/partners/${partnerGUID}/subscriptions?PageNumber=${page}&PageSize=${limit}`, config)
}

ManagementApiService.getOwnerSubscriptions = (ownerGUID, page, limit) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/owner/${ownerGUID}/subscriptions?PageNumber=${page}&PageSize=${limit}`, config)
}

ManagementApiService.getSubscriptionDetail = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/details?IncludeStats=True&DateFrom=${from}&DateTo=${to}`, config)
}

ManagementApiService.getBioChannelSubscriptionDetails = (subscriptionGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/bio/details`, config)
}

ManagementApiService.getBioChannelSubscriptionDevices = (subscriptionGuid, page=1, limit=15, filters) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/bio/devices?PageNumber=${page}&PageSize=${limit}&Filter=${filters}`, config)
}

ManagementApiService.deleteDevice = (deviceName) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.delete(`${ManagementApiService.baseURL}/v2.0/devices/${deviceName}`, config)
}

ManagementApiService.editDeviceDescription = (deviceName, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.put(`${ManagementApiService.baseURL}/v2.0/devices/${deviceName}`, body, config);
}

ManagementApiService.getBioChannelSubscriptionStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/bio/stats?From=${from}&To=${to}`, config)
}

ManagementApiService.getBioChannelSubscriptionCSVStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/bio/stats/csv?From=${from}&To=${to}`, config)
}

ManagementApiService.getRemoteChannelSubscriptionStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/remote/stats?From=${from}&To=${to}`, config)
}
ManagementApiService.getEdeliveryChannelSubscriptionStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/edelivery/stats?From=${from}&To=${to}`, config)
}

ManagementApiService.getCentralizedChannelSubscriptionDetails = (subscriptionGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/centralized/details`, config)
}

ManagementApiService.getCentralizedChannelSubscriptionUsers = (subscriptionGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/centralized/users`, config)
}

ManagementApiService.deteleVIDMobileUser = (userGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.delete(`${ManagementApiService.baseURL}/v2.0/vidmobileuser/${userGuid}`, config)
}

ManagementApiService.createVIDMobileUser = (subscriptionGuid, VIDMobileUser) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.post(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/centralized/users`, VIDMobileUser, config)
}

ManagementApiService.editVIDMobileUser = (VIDMobileUser) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.put(`${ManagementApiService.baseURL}/v2.0/vidmobileuser`, VIDMobileUser, config)
}

ManagementApiService.getCentralizedChannelSubscriptionStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/centralized/stats?From=${from}&To=${to}`, config)
}

ManagementApiService.getCentralizedChannelSubscriptionCSVStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/centralized/stats/csv?From=${from}&To=${to}`, config)
}

ManagementApiService.editCentralizedUser = (subscriptionId, userGUID, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.put(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionId}/signaturechannels/centralized/users/${userGUID}`, body, config)
}

ManagementApiService.getStampChannelSubscriptionDetails = (subscriptionGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/stamp/details`, config)
}

ManagementApiService.getStampChannelSubscriptionStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/stamp/stats?From=${from}&To=${to}`, config)
}

ManagementApiService.getStampChannelSubscriptionCSVStats = (subscriptionGuid, from, to) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/stamp/stats/csv?From=${from}&To=${to}`, config)
}

ManagementApiService.deleteStampCertificate = (subscriptionGuid, certGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.delete(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/stampcertificate/${certGuid}`, config)
}

ManagementApiService.getPartners = (page=1, limit=15, filters) => {
  const querystringFilters = getQuerystring(filters);

  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/partners?PageNumber=${page}&PageSize=${limit}&${querystringFilters}`, config)
}
ManagementApiService.getAllPartners = () => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/partners`, config)
}

ManagementApiService.getPartnerDetail = (partnerGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/partners/${partnerGuid}`, config)
}

ManagementApiService.createPartner = (formData) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.post(`${ManagementApiService.baseURL}/v2.0/partners`, formData, config)
}

ManagementApiService.editPartner = (partnerGuid, formData) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.put(`${ManagementApiService.baseURL}/v2.0/partners/${partnerGuid}`, formData, config)
}

ManagementApiService.deletePartner = (partnerGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.delete(`${ManagementApiService.baseURL}/v2.0/partners/${partnerGuid}`, config)
}

ManagementApiService.getDocuments = (page=1, limit=15, filters) => {
  const querystringFilters = getQuerystring(filters);
   const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/documents?PageNumber=${page}&PageSize=${limit}&${querystringFilters}`, config)
}

ManagementApiService.getDocumentDetail = (documentGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/documents/${documentGuid}/details`, config)
}

ManagementApiService.sendReminder = (signerGuid, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.put(`${ManagementApiService.baseURL}/v2.0/signatures/${signerGuid}/reminder`, body,  config)
}

ManagementApiService.deleteDocument = (documentGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.delete(`${ManagementApiService.baseURL}/v2.0/documents/${documentGuid}`, config)
}

ManagementApiService.deleteSignedDocument = (documentGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.delete(`${ManagementApiService.baseURL}/v2.0/signeddocuments/${documentGuid}`, config)
}

ManagementApiService.deleteDocuments = (body) => {
  const config = {
    Authorization: `Bearer ${localStorage.getItem('cloud_token')}`
  };
  return axios.delete(`${ManagementApiService.baseURL}/v2.0/documents/`, { headers: config, data: body });
}

ManagementApiService.downloadDocument = (documentGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/documents/${documentGuid}`, config)
}

ManagementApiService.getCustomersFromAPartner = (partnerGuid, from, to, limit, page) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers?PartnerGuid=${partnerGuid}&IncludeStats=True&DateFrom=${from}&DateTo=${to}&PageNumber=${page}&PageSize=${limit}`, config);
}

ManagementApiService.getCustomersFromAPartnerWithoutStats = (partnerGuid, limit, page) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/customers?PartnerGuid=${partnerGuid}&PageNumber=${page}&PageSize=${limit}`, config);
}

ManagementApiService.getSubscriptionVIDmobileUsers = (subscriptionGUID) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/subscription/${subscriptionGUID}/vidmobileusers`, config)
}

ManagementApiService.getVIDstamperUsers = (subscriptionGUID) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/subscription/${subscriptionGUID}/vidstamperusers`, config)
}

ManagementApiService.getStamperCertificates = (subscriptionGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/stamp/certificates`, config)
}

ManagementApiService.createStampCertificate = (subscriptionGuid, certificate) => {
  // TODO esperar a gestión del scope partner / subscription para el token
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.post(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/stampcertificate`, certificate, config)
}

ManagementApiService.createQualifiedStampCertificate = (subscriptionGuid, qfCertificate) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.post(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/stampcertificate/uanataca`, qfCertificate, config)
}

ManagementApiService.getStamperImage = (subscriptionGuid, certificateGuid) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.get(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/signaturechannels/stamp/${certificateGuid}/image`, config)
}

ManagementApiService.editImageDescriptionImage = (subscriptionGuid, certificateGuid, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };
  return axios.put(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGuid}/stampcertificate/${certificateGuid}`, body, config)
}

ManagementApiService.getSubscriptionStats = (subscriptionGUID) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  const data = {
    "From": "01/01/2017",
    "To": "01/01/2021"
  }

  return axios.post(`${ManagementApiService.baseURL}/subscription/${subscriptionGUID}/stats/search`, data, config);
}

ManagementApiService.linkCentralizedUser = (centralizedUserGuid, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.put(`${ManagementApiService.baseURL}/v2.0/vidmobileusers/${centralizedUserGuid}/enroll`, body, config)
}

ManagementApiService.unlinkCentralizedUser = (centralizedUserGuid, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.put(`${ManagementApiService.baseURL}/v2.0/vidmobileusers/${centralizedUserGuid}/unenroll`, body, config)
}

ManagementApiService.linkDevice = (deviceName, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.put(`${ManagementApiService.baseURL}/v2.0/devices/${deviceName}/enroll`, body, config)
}

ManagementApiService.unlinkDevice = (deviceName, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.put(`${ManagementApiService.baseURL}/v2.0/devices/${deviceName}/unenroll`, body, config)
}

ManagementApiService.linkStamperCertificate = (certGuid, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.put(`${ManagementApiService.baseURL}/v2.0/stampers/${certGuid}/enroll`, body, config)
}

ManagementApiService.unlinkStamperCertificate = (certGuid, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.put(`${ManagementApiService.baseURL}/v2.0/stampers/${certGuid}/unenroll`, body, config)
}

ManagementApiService.postSubscriptionFeatures = (subscriptionGUID, data) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.post(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGUID}/features`, data, config);
}

ManagementApiService.putSubscriptionFeatures = (subscriptionGUID, body) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.put(`${ManagementApiService.baseURL}/v2.0/subscription/${subscriptionGUID}/features`, body, config)
}

ManagementApiService.addNewTrustedCertType = (data) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('cloud_token')}` }
  };

  return axios.post(`${ManagementApiService.baseURL}/v2.0/certificates/cacerts`, data, config);
}

export default ManagementApiService