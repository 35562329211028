
export const initializeApp = (data) => {
  return {
    type: 'INITIALIZED_APP',
    payload: data
  }
}

export const partnersRetrieved = (partners) => {
  return {
    type: 'PARTNERS_RETRIEVED',
    payload: partners
  }
}

export const customersRetrieved = (customers) => {
  return {
    type: 'CUSTOMERS_RETRIEVED',
    payload: customers
  }
}

export const subscriptionsRetrieved = (subscriptions) => {
  return {
    type: 'SUBSCRIPTIONS_RETRIEVED',
    payload: subscriptions
  }
}

export const postsRetrieved = (posts) => {
  return {
    type: 'POSTS_RETRIEVE',
    payload: posts
  }
}


export const currentUserSelected = (currentUser) => {
  return {
    type: 'CURRENT_USER_SELECTED',
    payload: currentUser
  }
}