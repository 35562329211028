import { all, call, put, takeEvery, takeLatest, fork } from '@redux-saga/core/effects'
import ManagementApiService from 'services/ManagementApiService'


import {subsPanSetCurrent} from 'redux/actions/Common'
import { subsPanSetCurrentStats } from '../actions/Common'

export function* subsPanCurrent() {
  yield takeEvery('SUBSPAN_CURRENT', function*({subscription}) {
    yield put(subsPanSetCurrent(subscription))
  })
}

export function* subsPanCurrentStats() {
  yield takeEvery('SUBSPAN_CURRENT_STATS', function*({stats}) {
    yield put(subsPanSetCurrentStats(stats))
  })
}


export default function* rootSaga() {
  yield all([
    fork(subsPanCurrent),
  ])
}