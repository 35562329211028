import { getEnvironmentURL } from "utils/getEnvironmentURL";
import { axiosInstance } from "./AdminSiteService";

const AuthApiService = {};

// This is DEV URL
// TO DO: Create service to get URL depending on environment 
const baseApiUrlDev = 'http://localhost:3001/api/auth';
const baseApiUrlPre = 'https://pre-console.vidsigner.net/api/auth';
const baseApiUrlPro = 'https://console.vidsigner.net/api/auth';

const baseApiUrl = getEnvironmentURL(baseApiUrlPro, baseApiUrlPre, baseApiUrlDev);

AuthApiService.getToken = () => {
  return axiosInstance.get(`${baseApiUrl}/token`);
}

export default AuthApiService;