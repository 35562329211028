import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Api from './Api'
import Common from './Common'

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Api(),
    Common()
  ]);
}
