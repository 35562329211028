import { all, call, put, takeEvery, fork } from '@redux-saga/core/effects'
import { currentUserSelected, postsRetrieved, subscriptionsRetrieved, partnersRetrieved, customersRetrieved, initializeApp } from 'redux/actions/Api'
import AxiosTestService from 'services/AxiosTestService'
import ManagementApiService from 'services/ManagementApiService'

export function* initialize() {
  yield takeEvery('INITIALIZE_APP', function*() {
    const partners = yield call(ManagementApiService.getPartners)
    const subscriptions = yield call(ManagementApiService.getCustomerSubscriptions)
    const customers = yield call(ManagementApiService.getCustomers)

    yield put(partnersRetrieved(partners.data))  
    yield put(customersRetrieved(customers.data))  
    yield put(subscriptionsRetrieved(subscriptions.data))  
  })
}


export function* getPosts() {
  yield takeEvery('POSTS_RETRIEVED', function*() {
    const posts = yield call(AxiosTestService.getPosts)
    yield put(postsRetrieved(posts.data))  
  })
}


export function* setCurrentUser() {
  yield takeEvery('SET_CURRENT_USER', function*({currentUser}) {
    localStorage.setItem('CURRENT_USER', currentUser?.uuid)
    yield put(currentUserSelected(currentUser))  
  })
}

export default function* rootSaga() {
  yield all([
    fork(initialize),
    fork(getPosts),
    fork(setCurrentUser)
  ])
}