const initState = {
  posts: null,
  // currentUser: {
  //   type: "owner",
  //   permissions: {
  //     OP1: true,
  //     OP2: true,
  //     OP3: true,
  //     OP4: true,
  //     OP5: true,
  //     OP6: true,
  //     OP7: true,
  //     OP8: true,
  //     OP91: true,
  //     OP92: true,
  //     OP93: true,
  //     OP94: true,
  //     OP10: true,
  //     OP11: true,
  //     OP12: true,
  //     OP13: true,
  //     PP16: true
  //   }
  // },
  currentUser: null
}


const api = (state = initState, action) => {
  switch (action.type) {
    case 'POSTS_RETRIEVE':
      return {
        ...state,
        posts: action.payload
      }
    case 'CURRENT_USER_SELECTED':
      return {
        ...state,
        currentUser: action.payload
      }
    case 'INITIALIZED_APP':
      return {
        ...state,
        data: action.payload
      }
    case 'CUSTOMERS_RETRIEVED':
      return {
        ...state,
        customers: action.payload
      }
    case 'PARTNERS_RETRIEVED':
      return {
        ...state,
        partners: action.payload
      }
    case 'SUBSCRIPTIONS_RETRIEVED':
      return {
        ...state,
        subscriptions: action.payload
      }

    default:
      return state
  }
}

export default api