import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from 'utils'
import { onMobileNavToggle } from "redux/actions/Theme";
import { useLocation } from "react-router-dom";
import * as perRes from '../../services/PermissionsResolver'
import { Fragment } from "react";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }


  const resolvePermission = (permission) => {
    return true;
  }

  const [currentPath, setCurrentPath] = useState('')
  const location = useLocation();

  useEffect(() => {

    setCurrentPath(location.pathname)
  })

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) => {
        // Simple menu
        if (menu.submenu.length === 0) {
          return <Fragment key={menu.key}>
          {perRes.resolve(menu.permission, props.currentUser) ?
            <Menu.Item key={menu.key} className={currentPath.includes(menu.path) ? 'ant-menu-item-selected achotio' : ''}>
              {currentPath.includes(menu.path)  ? <img className="img-fluid" src={'/img/sidenav/' + menu.active_icon} alt="" />
                : <img className="img-fluid" src={'/img/sidenav/' + menu.icon} alt="" />}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
            </Menu.Item> : ''}
        </Fragment>
        }

        // Menu with subitems
        return <Fragment key={menu.key}>
          {perRes.resolve(menu.permission, props.currentUser) ?
            <Menu.ItemGroup key={menu.key} className={currentPath.includes(menu.path) ? 'ant-menu-item-selected achotio ant-menu-item' : 'ant-menu-item'}>
              {currentPath.includes(menu.path)  ? <img className="img-fluid" src={'/img/sidenav/' + menu.active_icon} alt="" />
                : <img className="img-fluid" src={'/img/sidenav/' + menu.icon} alt="" />}
              <span>{setLocale(localization, menu?.title)}</span>
              {
                menu.submenu.map((item) => {
                  return <Fragment key={item.key}>
                  {perRes.resolve(item.permission, props.currentUser) ?
                    <div key={item.key} className={currentPath.includes(item.path) ? 'ant-menu-item-selected achotio' : ''}>
                      <p>{setLocale(localization, item?.title)}</p>
                      {menu.path ? <Link onClick={() => closeMobileNav()} to={item.path} /> : null}
                    </div> : ''}
                </Fragment>
                })
              }
            </Menu.ItemGroup> : ''}
        </Fragment>
    }

      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = (state) => {
  const { sideNavTheme, topNavColor } = state.theme;
  const currentUser = state.api.currentUser;
  return { sideNavTheme, topNavColor, currentUser };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
