const initState = {
  subspan_subscription: null,
}


const common = (state = initState, action) => {
  switch (action.type) {
    case 'SUBSPAN_SET_CURRENT':
      return {
        ...state,
        subspan_subscription: action.payload
      }
    case 'SUBSPAN_SET_CURRENT_STATS':
      return {
        ...state,
        subspan_subscription_stats: action.payload
      }
    default:
      return state
  }
}


export default common