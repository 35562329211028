import { axiosInstance } from "services/AdminSiteService";

export const logout = (dispatch) => {
  localStorage.clear();
  axiosInstance.defaults.headers['Avatar-Type'] = "";
  axiosInstance.defaults.headers['Avatar-Guid'] = "";
  axiosInstance.defaults.headers['User-Full-Name'] = "";

  localStorage.removeItem("Avatar-Type");
  localStorage.removeItem("Avatar-Guid");
  localStorage.removeItem("User-Full-Name");

  dispatch({ type: 'SET_CURRENT_USER', currentUser: null });
  setTimeout(() => {
    window.location.href = "/auth/login";
  }, 1000);
}