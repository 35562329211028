import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  PoweroffOutlined,
  TeamOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { useIntl } from 'react-intl'
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const NavProfile = ({ currentUser }) => {
  const intl = useIntl();
  const history = useHistory();

  const menuItem = [
    {
      title: intl.formatMessage({ id: 'navprofile.edit' }),
      icon: EditOutlined,
      path: "/app/settings"
    },
    {
      title:intl.formatMessage({ id: 'navprofile.avatars' }),
      icon: TeamOutlined,
      path: "/app/settings/avatars"
    },
    {
      title: intl.formatMessage({ id: 'navprofile.logout' }),
      icon: PoweroffOutlined,
      path: "/auth/login"
    }
  ]

  const handleNavigation = (route) => {
    history.push(route);
  }


  const profileImg = "/img/user_profile.svg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={(currentUser?.ProfileImage === '' || currentUser?.ProfileImage === null) ? profileImg : currentUser?.ProfileImage} />
          <div className="pl-3">
            <h4 className="mb-0">{currentUser?.name}</h4>
            <span className="text-muted">{currentUser?.type}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} className="profile-menu-item">
                <div onClick={() => handleNavigation(el.path)}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </div>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item className="profile-menu-item">
          <span className="profile-menu-name">{currentUser?.name}</span>
          <Avatar src={(currentUser?.ProfileImage === '' || currentUser?.ProfileImage === null) ? profileImg : currentUser?.ProfileImage} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

const mapStateToProps = (state) => (
  {
    currentUser: state.api.currentUser,
  }
)

export default connect(mapStateToProps, { signOut })(NavProfile)
