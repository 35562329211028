import antdEnUS from 'antd/es/locale/en_US';
import esMsg from "../locales/es_ES.json";

const EsLang = {
  antd: antdEnUS,
  locale: 'es-ES',
  messages: {
    ...esMsg
  },
};
export default EsLang;
