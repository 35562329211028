import axios from 'axios';

export const axiosInstance = axios.create({
  withCredentials: true,
  allow_headers: ['Content-Type', 'Authorization', 'locale'],
  headers: {
    locale: localStorage.getItem("locale") || 'en',
    "Avatar-Type": localStorage.getItem("Avatar-Type"),
    "Avatar-Guid": localStorage.getItem("Avatar-Guid"),
    "User-Full-Name": localStorage.getItem("User-Full-Name"),
  }
});