export const getEnvironmentURL = (urlPRO, urlPRE, urlDEV) => {
  if (process.env.REACT_APP_ENV === "preproduction") return urlPRE;
  if (process.env.REACT_APP_ENV === "production") return urlPRO;
  return urlDEV;
}

export const getClientId = () => {
  const CLIENT_ID_PRE = "632b6a7b-3bb0-477b-b0cd-6a3c8bbe3101";
  const CLIENT_ID_PRO = "1595a42a-ce3f-4269-b751-3d78f872487c";

  if (process.env.REACT_APP_ENV === "production") return CLIENT_ID_PRO;
  return CLIENT_ID_PRE;
}

export const getConfig = () => {
  const PRE_CONFIG = {
    auth: {
      username: "unittestclientdemo",
      password: "1234"
    }
  };

  const PRO_CONFIG = {
    auth: {
      username: "adminsiteclientid",
      password: "j7H3dp29cb"
    }
  };

  if (process.env.REACT_APP_ENV === "production") return PRO_CONFIG;
  return PRE_CONFIG;
}