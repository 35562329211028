export const usersMock = [
  {
    "uuid": 0,
    "name": "UserOwner1",
    "type": "owner",
    "permissions":
    {
      "OP1": true,
      "OP2": true,
      "OP3": true,
      "OP4": true,
      "OP5": true,
      "OP6": true,
      "OP7": true,
      "OP8": true,
      "OP91": true,
      "OP92": true,
      "OP93": true,
      "OP94": true,
      "OP10": true,
      "OP11": true,
      "OP12": true,
      "OP13": true,
    }
  },
  {
    "uuid": 1,
    "name": "UserPartner1",
    "type": "partner",
    "permissions":
    {
      "PP1": true,
      "PP2": true,
      "PP3": true,
      "PP4": true,
      "PP5": true,
      "PP6": true,
      "PP7": true,
      "PP8": true,
      "PP9": true,
      "PP10": true,
      "PP11": true,
      "PP12": true,
      "PP13": true,
      "PP14": true,
      "PP15": true,
      "PP16": true,
    }
  },
  {
    "uuid": 2,
    "name": "UserCustomer1",
    "type": "customer",
    "permissions": {
      "CP1": true,
      "CP2": true,
      "CP3": true,
      "CP4": true,
      "CP5": true,
      "CP6": true,
      "CP7": true,
      "CP8": true,
      "CP9": true,
      "CP10": true,
      "CP11": true,
      "CP12": true,
      "CP13": true,
      "CP14": true,
    }
  }
]