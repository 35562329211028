import React from 'react'

export default function Footer() {
	return (
		<footer className="footer">
			<div className="mob-footer">
				<p>Copyright © {new Date().getFullYear()} Validated ID SL</p>
			</div>
		</footer>
	)
}

