const FirebaseConfig = {
  apiKey: 'a',
  authDomain: 'a',
  databaseURL: 'a',
  projectId: 'a',
  storageBucket: 'a',
  messagingSenderId: 'a',
  appId: 'a',
  measurementId: 'a2'
};

export default FirebaseConfig

