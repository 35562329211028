export const subsPanSetCurrent = (subscription) => {
  return {
    type: 'SUBSPAN_SET_CURRENT',
    payload: subscription
  }
}

export const subsPanSetCurrentStats = (stats) => {
  return {
    type: 'SUBSPAN_SET_CURRENT_STATS',
    payload: stats
  }
}